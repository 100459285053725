
import {map,  tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { SecureHttp } from '../auth/securehttp';
import { Observable } from 'rxjs';
import { ToastService } from "../common/services/toast.service";
import { BillingAccount } from '../models/billing-account.model';
import { DocumentBlob } from '../models/document-blob.model';
import { FileService } from '../common/services/file.service';

@Injectable()
export class BillingService {

    constructor(
        private http: SecureHttp,
        private fileService: FileService,
        private toast: ToastService
    ) {
    }

    getBills(account?: string): Observable<BillingAccount[]> {
        let url = `api/billing/bills`
        let params = new HttpParams();
        if (account) {
            params = params.set('account', account);
        }
        return this.http.get(url, { params: params }).pipe(map(response => response as BillingAccount[]))
    }

    getBill(id: string, format: string): Observable<DocumentBlob> {
        let url = `api/billing/bill`;
        let params = new HttpParams();
        params = params.set('id', id);
        params = params.set('format', format);
        return this.http.get(url, { params: params, responseType: "blob" }).pipe(
            map((response: any) => {
                let disposition = response.headers.get('content-disposition');
                let match = disposition.match(/filename=(.+)/);
                return { Id: id, Content: response, FileName: match[match.length - 1] } as DocumentBlob;
            }));
    }

    viewBill(id: string, format: string): Observable<any> {
        let url = `api/billing/bill/download`;
        let params = new HttpParams();
        params = params.set('id', id);
        params = params.set('format', format);
        return this.http.get(url, { params: params }).pipe(tap((response) => {
            if (response == null) {
                this.toast.error('Could not find document data', 'Error');
                return;
            }
            this.fileService.downloadDocument(response);
        }));
    }

    viewBills(account: string, period: string): Observable<any> {
        let url = `api/billing/bills/download`;
        let params = new HttpParams();
        params = params.set('account', account);
        params = params.set('period', period);
        return this.http.get(url, { params: params }).pipe(tap((response) => {
            if (response == null) {
                this.toast.error('Could not find document data', 'Error');
                return;
            }
            this.fileService.downloadDocument(response);
        }));
    }


}